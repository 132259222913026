.free {
  position: relative;
  background-color: var(--color-blue-light);
  padding: 2rem 0;
}

.bens-section-02-item {
  color: var(--color-blue);
  font-size: 4rem;
  margin: 0 1rem;
}

.bens-section-02-title > h2 {
  color: var(--color-blue);
  font-size: 2rem;
  font-weight: bold;
  margin: 0.5rem auto;
  text-align: center;
}

.bens-section-02-title > h2 > span.text-is-red {
  color: var(--color-red);
}

.free-cta {
  text-decoration: none;
  background-color: var(--color-green-dark);
  border-radius: 1rem 2rem 1rem 1rem;
  padding: 0.75rem 3rem;
  color: var(--color-white);
  margin: 2rem auto 0 auto;
  font-size: 1rem;
  font-weight: bold;
  box-shadow: 0 1px 2px 0 var(--shadow-25);
  transition: all 0.2s linear;
}

.free-cta:hover,
.free-cta:focus,
.free-cta:active {
  background-color: var(--color-blue);
  box-shadow: 0 2px 4px 0 var(--shadow-25);
  color: var(--color-white);
}
