/* Section 08 */
.solutions {
  padding: 4rem 0;
  position: relative;
  z-index: 1;
}

.solutions-back {
  position: absolute;
  z-index: 0;
  top: 0;
  bottom: auto;
  right: auto;
  left: -65vw;
  margin: auto;
  width: 100vw;
  height: 100vw;
  border-radius: 100%;
  padding: 1rem;
}

.solutions > .bens-container {
  position: relative;
  z-index: 1;
}

.section-08-box {
  flex: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.section-08-box > .box-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.section-08-box > .box-col > .box-img {
  object-fit: contain;
  max-width: 460px;
  margin: 1rem auto;
}

.section-08-box > .box-col > .box-title {
  font-size: 1.75rem;
  font-weight: bold;
  line-height: 1.6;
  text-align: left;
  margin: 0.5rem auto;
  color: var(--color-blue);
}

.section-08-box .box-desc-img {
  object-fit: contain;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.section-08-box .box-long-desc {
  color: var(--color-dark);
  font-size: 1rem;
  line-height: 1.6;
  font-weight: 300;
}

.section-05-box .box-store {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.solutions-top {
  display: flex;
  flex-wrap: wrap;
}

.solutions-top > .box-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: calc(100% / 2);
}

.solutions-top-right {
  padding: 2rem;
}

.solutions-top-right > h2 {
  text-align: left;
  background: linear-gradient(
    to right,
    var(--color-blue) 0%,
    var(--color-green) 100%
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.75rem;
  font-weight: bold;
  line-height: 1.6;
  margin: 1rem 0;
}

.solutions-top-right a.btn-green {
  background-color: var(--color-green-dark);
}

.solutions-top-right a {
  text-decoration: none;
  background-color: var(--color-blue);
  border-radius: 1rem 2rem 1rem 1rem;
  color: var(--color-white);
  padding: 1rem 2rem;
  margin: 0.5rem auto 0.5rem 0;
  transition: all 0.2s linear;
  box-shadow: 0 1px 4px 0 var(--shadow-25);
}

.solutions-top-right a:hover,
.solutions-top-right a:focus,
.solutions-top-right a:active {
  background-color: var(--color-blue-dark);
  box-shadow: 0 2px 8px 0 var(--shadow-50);
}

.media-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  height: 24rem;
}

.media-item {
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 100%;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 1px 1px 0 var(--shadow-primary-25);
  transition: all 0.2s linear;
}

.media-item:hover,
.media-item:focus,
.media-item:active {
  text-decoration: none;
  box-shadow: 0 8px 32px 0 var(--shadow-primary-50);
  transform: scale(1.1);
}

.media-group > .media-item:last-child {
  align-self: flex-end;
}

.media-item > h4 {
  position: absolute;
  color: var(--color-white);
  text-align: center;
  margin: 1rem 2rem;
  font-size: 1.1rem;
  line-height: 1.6;
  z-index: 1;
}

.media-item > img {
  height: 15rem;
  width: auto;
  object-fit: contain;
}

/* 2xl */
@media (max-width: 1536px) {
}

/* xl */
@media (max-width: 1280px) {
  .section-08-box > .box-col > .box-title {
    text-align: center;
  }
}

/* lg */
@media (max-width: 1024px) {
  .section-08-box > .box-col > .box-title {
    text-align: center;
  }

  .section-08-box {
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }

  .section-08-box > .box-col {
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0;
    padding: 1rem;
    width: 100%;
  }
}

/* md */
@media (max-width: 768px) {
  .section-08-box > .box-col > .box-title {
    text-align: center;
  }

  .section-08-box {
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }

  .section-08-box > .box-col {
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0;
    padding: 1rem;
    width: 100%;
  }

  .solutions-top-right > h2,
  .solutions-top-right > a {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
}

/* sm */
@media (max-width: 640px) {
  .solutions-top {
    flex-wrap: wrap-reverse;
  }
  .solutions {
    padding: 1rem 0;
  }

  .media-group {
    flex-direction: column;
    height: auto;
  }

  .media-group > .media-item {
    margin: 1rem auto;
  }

  .section-08-box > .box-col > .box-title {
    text-align: center;
  }

  .section-08-box {
    flex-direction: column-reverse;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }

  .section-08-box > .box-col {
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0;
    padding: 1rem;
    width: 100%;
  }

  .section-08-box > .box-col > .box-img {
    max-width: 100%;
    width: 100%;
    height: auto;
    margin: 1rem auto;
  }

  .section-08-box .box-desc-img {
    max-width: 100%;
  }
}
