#calculator {
  position: relative;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.calculator {
  position: relative;
  z-index: 0;
  background-color: var(--color-transparent);
  display: flex;
  flex-wrap: wrap;
  width: 22rem;
  overflow-x: hidden;
  margin: auto;
}

.calculator-header {
  background-color: var(--color-blue);
  color: var(--color-white);
  padding: 1rem 1.25rem;
  border-radius: 1em 4em 0 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex: 100%;
}
.calculator-header > h3 {
  line-height: 1;
  margin: 0;
  font-weight: 400;
  font-size: 1.25rem;
}

.calculator-wrapper {
  display: flex;
  flex-direction: column;
  flex: 100%;
  padding: 1rem;
  background-color: var(--color-white);
}
.calculator-input-primary {
  background-color: var(--color-blue-light);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 0.75em;
  padding: 0.75em;
  position: relative;
}
.calculator-input-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.bens-label {
  font-size: 0.8rem;
  color: var(--color-gray-dark);
}
.bens-input {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  appearance: none;
  border: none;
  background-color: var(--color-transparent);
  outline: none;
  width: 100%;
  font-size: 1.5rem;
  padding: 0;
}
.bens-input:active,
.bens-input:focus,
.bens-input:hover {
  outline: none;
  border: none;
  appearance: none;
}

.bens-select-primary {
  cursor: pointer;
  border: 1px solid var(--color-blue);
  border-radius: 1.5em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;
}
.bens-select-option {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 7rem;
  margin-left: 0.5em;
}

.bens-select-primary.is-select-box,
.bens-select-secondary.is-select-box {
  position: absolute;
  z-index: 10;
  top: 0.5rem;
  right: 1rem;
  left: auto;
  display: flex;
  flex-direction: column;
  background-color: var(--color-white);
  box-shadow: 0 1px 4px 0 var(--shadow-25);
  overflow: hidden;
}
.is-select-box > .bens-select-option {
  padding: 0.5rem 0;
  margin: 0;
  border-bottom: 1px solid var(--color-blue);
  cursor: pointer;
}
.is-select-box > .bens-select-option:last-child {
  border-bottom: none;
}
.is-select-box > .bens-select-option:hover,
.is-select-box > .bens-select-option:focus,
.is-select-box > .bens-select-option:active {
  background-color: var(--color-blue-light);
}

.bens-select-primary.is-hidden,
.bens-select-secondary.is-hidden {
  display: none;
}

.bens-select-option-name {
  font-size: 1.5em;
}

.bens-select-option-img {
  height: 2.5em;
  width: auto;
  max-width: 100%;
  object-fit: contain;
}

.calculator-tax {
  margin: 0.5em 1em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.tax-value {
  color: var(--color-green);
  font-size: 1.25rem;
  font-weight: 500;
}
.tax-label {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.tax-desc {
  font-weight: 500;
  font-size: 0.8rem;
}
.tax-info {
  font-weight: 300;
  font-size: 0.7rem;
}
.tax-time {
  font-weight: 300;
  font-size: 0.7rem;
}

.calculator-input-secondary {
  background-color: var(--color-purple-light);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 0.75em;
  padding: 0.75em;
  position: relative;
}
.bens-select-secondary {
  cursor: pointer;
  border: 1px solid var(--color-purple);
  border-radius: 1.5em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.calculator-fee {
  margin: 0.5em 1em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.fee-value {
  color: var(--color-blue);
  font-size: 1.25rem;
  font-weight: 500;
}
.fee-label {
  font-size: 0.8rem;
  font-weight: 500;
}

.calculator-notices {
  margin: 0.5em 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  text-align: left;
}

.calculator-notices > span {
  font-size: 0.6rem;
  color: var(--color-blue);
}

.calculator-footer {
  display: flex;
  flex-direction: column;
  flex: 100%;
  padding: 1em 1.5em;
  background-color: var(--color-gray);
  border: 1px solid var(--color-green);
  border-radius: 0 0 1em 1em;
}
.footer-label {
  font-size: 1.25rem;
  line-height: 1.5;
}
.footer-value {
  font-size: 1.75rem;
  line-height: 1.5;
  font-weight: 600;
  color: var(--color-green);
  margin: 0.25rem 0;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
}
.footer-value > span {
  font-size: 1.2rem;
  margin: 0 0.5rem 0 0;
}
.footer-notice {
  font-size: 0.7rem;
}

/* 2xl */
@media (max-width: 1536px) {
}

/* xl */
@media (max-width: 1280px) {
}

/* lg */
@media (max-width: 1024px) {
  #calculator {
    margin-left: auto;
    margin-right: auto;
  }
}

/* md */
@media (max-width: 768px) {
  #calculator {
    margin-left: auto;
    margin-right: auto;
  }
}

/* sm */
@media (max-width: 640px) {
  .calculator-back-img {
    display: none;
  }
  .calculator {
    margin: 1rem auto;
    max-width: 100vw;
    width: calc(100vw - 3rem);
  }
  .bens-select-primary {
    padding: 0;
    margin: auto;
  }
  .bens-select-primary.is-select-box,
  .bens-select-secondary.is-select-box {
    right: 0.5rem;
  }
  .bens-select-option,
  .is-select-box > .bens-select-option {
    padding: 0.25rem;
    margin-left: auto;
  }
  .bens-select-option-name {
    font-size: calc(100vw / 16);
    margin-left: 0.25rem;
  }
  .bens-select-option-img {
    margin-left: 0.5rem;
    height: calc(100vw / 10);
    width: auto;
  }
  .calculator-tax,
  .calculator-fee {
    margin: 0.25rem 0.5rem;
  }
  .bens-input,
  .tax-value,
  .fee-value {
    font-size: calc(100vw / 20);
  }
  .tax-label {
    text-align: right;
  }
  .tax-label > * {
    display: flex;
  }
  .bens-label,
  .tax-info,
  .tax-desc {
    font-size: calc(100vw / 36);
  }
  .calculator-notices > span {
    font-size: calc(100vw / 38);
  }
  .calculator-footer .footer-label {
    font-size: calc(100vw / 22);
  }
  .calculator-footer .footer-value {
    font-size: calc(100vw / 14);
  }
  .calculator-footer .footer-notice {
    font-size: calc(100vw / 38);
  }
}
