#bens-menu-mobile {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-transparent);
  transition: all 0.2s linear;
}

#bens-menu-mobile.is-hidden {
  display: none;
}

#mb-shadow {
  position: absolute;
  z-index: 1001;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--shadow-50);
  transition: all 0.2s linear;
}

#mb-shadow.is-hidden {
  display: none;
}

#mb-wrapper {
  position: absolute;
  z-index: 1002;
  top: 0;
  left: auto;
  right: 0;
  bottom: 0;
  background-color: var(--color-white);
  width: 16rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-x: hidden;
  padding: 0 0 5rem 0;
  transition: all 0.2s linear;
}

#mb-wrapper.is-hidden {
  right: -18rem;
}

.mb-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-blue);
  width: 100%;
}

.mb-row>.bens-logo {
  padding: 1rem;
  margin: 0;
}

.mb-section-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: var(--color-gray-dark);
  margin: 0;
  padding: 0rem 1.5rem;
  width: 100%;
  font-size: 0.9rem;
}

.mb-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 0 1rem 0;
  padding: 0;
  width: 100%;
}

.mb-section:last-child {
  margin: 0;
}

.mb-section.mb-secondary {
  margin-top: 1rem;
}

/* Primary Menu */
.mb-primary>a {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--color-green);
  padding: 0.5rem 1.5rem;
  transition: all 0.2s linear;
  width: 100%;
  background-color: var(--color-white);
  border-bottom: 1px solid var(--color-gray);
  border-left: 0.25rem solid var(--color-gray);
}

.mb-primary>a:last-child {
  border-bottom: none;
}

.mb-primary>a:hover,
.mb-primary>a:focus,
.mb-primary>a:active {
  background-color: var(--color-green-light);
  border-left: 0.25rem solid var(--color-green);
  color: var(--color-green);
}

.mb-primary>a.is-active {
  background-color: var(--color-green-light);
  border-left: 0.25rem solid var(--color-green);
  color: var(--color-green);
}

/* Secondary Menu */
.mb-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0;
}

.mb-item-label,
.mb-item>a {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.5rem 1.5rem;
  color: var(--color-blue);
  cursor: pointer;
  transition: all 0.2s linear;
  border-bottom: 1px solid var(--color-gray);
  border-left: 0.25rem solid var(--color-gray);
}

.mb-item-label:last-child {
  border-bottom: none;
}

.mb-item-label>img {
  width: 1rem;
  height: 1rem;
}

.mb-item-label:hover,
.mb-item-label:active,
.mb-item-label:focus {
  background-color: var(--color-blue-light);
  color: var(--color-blue);
  border-left: 0.25rem solid var(--color-blue);
}

.mb-item-list {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: var(--color-gray);
}

.mb-item-list>a {
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  color: var(--color-dark);
  padding: 0.5rem 1.5rem;
  transition: all 0.2s linear;
  background-color: var(--color-gray);
  border-bottom: 1px solid var(--color-white);
}

.mb-item-list>a.app-ios {
  background-color: var(--color-black);
}

.mb-item-list>a.app-android {
  background-color: var(--color-black);
}

.mb-item-list>a.app-web {
  background-color: var(--color-blue);
}

.mb-item-list>.is-separate {
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  width: 100%;
  color: var(--color-dark);
  padding: 0.5rem 1.5rem;
  transition: all 0.2s linear;
  background-color: var(--color-gray);
  border-bottom: 1px solid var(--color-white);
  border-left: 0.25rem solid var(--color-white);
}

.mb-item-list.is-show {
  display: flex;
}

/* Account Menu */
.mb-account {
  margin-top: 1rem;
}

/* Download Menu */
.mb-item-list.has-store-badge>a>img {
  height: 3rem;
  max-width: 100%;
  object-fit: contain;
  margin: 0.25rem auto;
}

/* 2xl */
@media (max-width: 1536px) {}

/* xl */
@media (max-width: 1280px) {}

/* lg */
@media (max-width: 1024px) {}

/* md */
@media (max-width: 768px) {}

/* sm */
@media (max-width: 640px) {
  .swiss-made {
    margin-right: 0.75rem;
  }
}