.advantages {
  padding: 0 0 14rem 0;
  position: relative;
  z-index: 1;
}

.box-title {
  background: linear-gradient(
    to right,
    var(--color-blue) 0%,
    var(--color-green) 100%
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  text-align: center;
  width: 100%;
  margin: 2rem auto 4rem auto;
}

.box-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}

.box-item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: calc(100% / 4);
  padding: 1rem;
}

.box-item > img {
  height: 8rem;
  width: auto;
  max-width: 100%;
  object-fit: contain;
  margin: 0 auto;
}

.box-item > h3 {
  color: var(--color-blue);
  margin: 2rem auto;
  text-align: center;
  font-size: 1.25rem;
}
.box-item > p {
  text-align: center;
  font-size: 1rem;
  line-height: 1.6;
}

/* 2xl */
@media (max-width: 1536px) {
}

/* xl */
@media (max-width: 1280px) {
  .box-item {
    flex: calc(100% / 2);
  }
}

/* lg */
@media (max-width: 1024px) {
  .box-item {
    flex: calc(100% / 2);
  }
}

/* md */
@media (max-width: 768px) {
  .box-item {
    flex: 100%;
  }
}

/* sm */
@media (max-width: 640px) {
  .box-item {
    flex: 100%;
  }
}
