@import 'bootstrap/dist/css/bootstrap.min.css';

:root {
  --color-transparent: transparent;
  --color-white: #ffffff;
  --color-black: #000000;
  --color-gray: #f9f9f9;
  --color-gray-dark: #857f7f;
  --color-gray-dark-1: #333;
  --color-turquoise: #00d9e1;
  --color-blue: #3589a1;
  --color-blue-light: #eff9fd;
  --color-blue-light-1: #65e6a7;
  --color-blue-dark: #1d748d;
  --color-red: #b20000;
  --color-red-dark: #8f0000;
  --color-red-light: #ff4438;
  --color-green: #6abe71;
  --color-green-dark: #429949;
  --color-green-light: #f3faf3;
  --color-green-light-1: #9dd8a1;
  --color-golden: #facc15;
  --color-purple: #0000a9;
  --color-purple-light: #f2f2ff;
  --shadow-25: rgba(0, 0, 0, 0.25);
  --shadow-50: rgba(0, 0, 0, 0.5);
  --shadow-primary-25: rgb(53, 137, 161, 0.25);
  --shadow-primary-50: rgb(53, 137, 161, 0.25);
}

a {
  color: var(--color-blue);
  transition: all 0.2s linear;
  text-align: center;
}

a:hover,
a:focus,
a:active {
  color: var(--color-blue-dark);
}

::selection {
  background-color: var(--color-blue-dark);
  color: var(--color-white);
  -webkit-text-fill-color: var(--color-white);
}

.is-red {
  color: var(--color-red);
  margin: 0 0.5rem;
}

body {
  display: flex;
  flex-direction: column;
  position: relative;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  background-color: var(--color-white);
  scroll-behavior: smooth;
}

.bens-section-title {
  flex: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: linear-gradient(
    to right,
    var(--color-blue) 0%,
    var(--color-green) 100%
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  margin: 1rem auto 4rem auto;
}

.bens-container {
  position: relative;
  max-width: 1140px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
}

.bens-container-fluid {
  max-width: 100%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
}

.bens-wrap {
  display: flex;
  flex-wrap: wrap;
}

.bens-row {
  display: flex;
  flex-direction: row;
}

.bens-col {
  display: flex;
  flex-direction: column;
}

.bens-justify-content-center {
  justify-content: center;
}

.bens-aling-items-center {
  align-items: center;
}

.bens-justify-content-space-between {
  justify-content: space-between;
}

.page-title {
  color: var(--color-blue);
  text-align: center;
  margin: 0 auto 0.5rem auto;
  font-size: 2rem;
  line-height: 1.5;
}

.page-desc {
  color: var(--color-blue);
  text-align: center;
  margin: 0 auto 0.5rem auto;
  font-size: 1.25rem;
  line-height: 1.5;
}

.page-download-mobile-app,
.page-plus-info,
.page-tutorial {
  background-color: var(--color-blue-dark);
  display: flex;
  flex-direction: column;
}

.dma-logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 16rem;
  margin: 30vh auto 1rem auto;
  background-color: var(--color-blue);
  border-radius: 1rem;
  padding: 1rem;
  text-align: center;
  box-shadow: 0 1px 4px 0 var(--shadow-50);
}

.dma-logo h1 {
  color: var(--color-white);
  margin: 1rem auto 2rem;
  font-size: 1.5rem;
  line-height: 1.5;
}

.dma-logo img {
  margin: 1rem auto;
}

.nos-solutions-de-change {
  display: flex;
  flex-direction: column;
  background-color: var(--color-blue-dark);
}

.nos-solutions-de-change img {
  height: auto;
  max-width: 100%;
  object-fit: contain;
}

.nos-container {
  display: flex;
  flex-direction: column;
  max-width: 30rem;
  margin: 5vh auto;
  background-color: var(--color-blue);
  box-shadow: 0 1px 10px 0 var(--shadow-50);
  border-radius: 1rem;
}

.nos-section {
  display: flex;
  flex-direction: column;
  margin: 0 0 2rem 0;
}

.nos-section:first-child {
  margin-top: 2rem;
}

.nos-section:last-child {
  margin: 0;
}

.nos-section > .has-image {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: var(--color-white);
  margin: 0;
  padding: 1rem;
  border-radius: 0 0 1rem 1rem;
}

.nos-section > .has-image > img {
  object-fit: contain;
  height: 3rem;
  width: auto;
}

.nos-section-logo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  background-color: var(--color-white);
  margin: 0 auto;
  border-radius: 0.5rem;
  box-shadow: 0 1px 4px 0 var(--shadow-50);
  padding: 1rem;
  max-width: 16rem;
}

.nos-section-logo > img {
  height: 5rem;
}

.nos-section-title {
  background-color: var(--color-red-light);
  color: var(--color-white);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  line-height: 2;
  text-align: center;
}

.nos-section-links {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
}

.nos-section-links > a {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  max-width: 8rem;
  background-color: var(--color-blue-dark);
  padding: 0.5rem;
  margin: 0.5rem;
  border-radius: 1rem;
  box-shadow: 0 1px 4px 0 var(--shadow-25);
  border: 0.2rem solid var(--color-white);
}

.nos-section-links > a > img {
  margin: auto;
  object-fit: contain;
  max-width: 100%;
  height: 3rem;
}

.bens-circle-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 2rem auto;
  gap: 1rem;
}

.bens-circle {
  background: linear-gradient(
    to right bottom,
    var(--color-blue) 50%,
    var(--color-green) 100%
  );
  width: 12rem;
  height: 12rem;
  padding: 1rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bold;
  color: var(--color-white);
  font-size: 1.25rem;
  border: 0.2rem solid var(--color-white);
  box-shadow: 0 1px 4px 0 var(--shadow-25);
}

.bens-cta-container {
  display: flex;
  flex-direction: column;
  margin: 1rem auto 2rem auto;
}

.cta-button {
  text-decoration: none;
  background-color: var(--color-green);
  border-radius: 1rem 2rem 1rem 1rem;
  padding: 0.75rem 3rem;
  color: var(--color-white);
  margin: 2rem auto 0 auto;
  font-size: 1rem;
  font-weight: bold;
  box-shadow: 0 1px 2px 0 var(--shadow-25);
  transition: all 0.2s linear;
}

.cta-button:hover,
.cta-button:focus,
.cta-button:active {
  background-color: var(--color-green-dark);
  box-shadow: 0 2px 4px 0 var(--shadow-25);
  color: var(--color-white);
}

.cta-button-secondary {
  background-color: var(--color-blue);
}
.cta-button-secondary:hover,
.cta-button-secondary:focus,
.cta-button-secondary:active {
  background-color: var(--color-blue-dark);
}

.bens-support {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 100%;
  flex: 100%;
  position: relative;
  z-index: 2;
  background-color: #f2d7a1;
  padding: 2rem 0;
  margin-top: 6rem;
}

.bs-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background-color: #f2d7a1;
  width: 100%;
  flex: 100%;
  max-width: 100%;
}

.bs-box-media {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 8rem;
  height: 8rem;
  border-radius: 1rem;
  overflow: hidden;
  margin-right: 1rem;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
}

.bs-box-media > img {
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  margin: 0;
  object-position: left top;
  background-color: var(--color-blue);
}

.bs-box-media > img.is-center {
  object-position: center top;
}

.bs-box-content {
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.5;
  color: #b08228;
  max-width: 40rem;
}

.bs-box-content .color-white {
  color: var(--color-white);
}

.bs-box-content .color-gold {
  color: var(--color-gold);
}

.bs-box-content .color-red {
  color: #981013;
}

@media screen and (max-width: 600px) {
  .nos-container {
    max-width: 22em;
  }
}

/* 2xl */
@media (max-width: 1536px) {
}

/* xl */
@media (max-width: 1280px) {
}

/* lg */
@media (max-width: 1024px) {
}

/* md */
@media (max-width: 768px) {
}

/* sm */
@media (max-width: 640px) {
  h2 {
    font-size: calc(100vw / 17);
  }
  .bens-circle-container {
    margin: 1rem auto;
    gap: 0;
  }
  .bs-box {
    flex-direction: column;
    padding: 1rem;
    text-align: center;
  }
  .bs-box-media {
    margin: 0 auto 2rem auto;
  }
  .bens-support {
    padding: 0.5rem 0;
  }
}
