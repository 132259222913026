.bens {
  padding: 4rem 0;
  position: relative;
  z-index: 1;
}

.bens > .bens-container > .bens-title {
  font-size: 1.75rem;
  font-weight: bold;
  line-height: 1.6;
  background: linear-gradient(
    to right,
    var(--color-blue) 0%,
    var(--color-green) 100%
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  margin: 1rem auto;
  flex: 100%;
}

.bens > .bens-container > .bens-text {
  font-size: 1rem;
  line-height: 1.6;
  text-align: center;
  margin: 1rem auto;
  flex: 100%;
}

.bens-container.has-circle {
  margin-top: 3rem;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

.bens-container > .bens-item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--color-blue-light);
  width: auto;
  height: 21rem;
  border-radius: 4rem;
  overflow: hidden;
  padding: 0.25rem 0.5rem;
  box-shadow: 0 1px 4px var(--shadow-25);
  cursor: pointer;
  background: linear-gradient(white, white) padding-box,
    linear-gradient(135deg, var(--color-blue), var(--color-green)) border-box;
  border: 4px solid transparent;
}

.bens-container > .bens-item > .bens-img {
  height: 4rem;
  width: 4rem;
  object-fit: contain;
  margin: 0.5rem auto;
  color: var(--color-blue);
}

.bens-container > .bens-item > .bens-title {
  font-size: 1.25rem;
  max-width: 15rem;
  line-height: 1.6;
  margin: 0.5rem auto;
  text-align: center;
  color: var(--color-blue);
}

.bens-container > .bens-item > .bens-desc {
  font-size: 1rem;
  max-width: 15rem;
  line-height: 1.6;
  margin: 0.5rem auto;
  text-align: center;
}

.bens-container > .bens-item > .order {
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.6;
  margin: 0.5rem auto;
  text-align: center;
  color: var(--color-green-dark);
}

/* 2xl */
@media (max-width: 1536px) {
}

/* xl */
@media (max-width: 1280px) {
}

/* lg */
@media (max-width: 1024px) {
}

/* md */
@media (max-width: 768px) {
  .bens {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

/* sm */
@media (max-width: 640px) {
}
