#bens-footer {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
}

.footer-top {
  display: flex;
  flex-direction: row;
  background: rgb(53, 137, 161);
  background: linear-gradient(180deg,
      rgba(29, 116, 141, 1) 0%,
      rgba(15, 58, 71, 1) 100%);
  padding: 7rem 0 2rem 0;
}

.footer-top>.bens-container {
  justify-content: center;
  align-items: flex-start;
  position: relative;
}

.footer-col {
  display: flex;
  flex-direction: column;
  flex: 100%;
}

.footer-col.has-links {
  padding: 2rem 0;
}

.footer-bottom {
  background-color: var(--color-white);
  padding: 1rem 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.footer-bottom>.bens-container {
  justify-content: space-between;
  align-items: center;
}

.footer-widget {
  display: flex;
  margin: 1em;
  justify-content: space-between;
}

.footer-widget.is-col {
  flex-direction: column;
}

.footer-widget.is-row {
  flex-direction: row;
  align-items: center;
}

.footer-bottom-image {
  border: 1px solid #eee;
  border-radius: .5rem;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .05);
  height: 6rem;
  margin: .5em;
  max-width: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  padding: .5rem;
  transition: all .2s linear;
  width: 6rem;
}

.footer-widget>.widget-title {
  color: var(--color-white);
  font-size: 1.2rem;
  line-height: 2;
  font-weight: 500;
  border-bottom: 1px solid;
  margin-bottom: 0.5rem;
}

.footer-widget>.widget-content {
  display: flex;
  flex-direction: column;
}

.footer-widget>.widget-content>a {
  border-bottom: 1px dashed var(--color-blue-dark);
  padding: 0.25rem 0;
  margin-bottom: 0.5rem;
  transition: all 0.2s linear;
}

.footer-widget>.widget-content>a:last-child {
  border-bottom: 1px dashed var(--color-transparent);
}

.footer-widget>.widget-content>a:hover,
.footer-widget>.widget-content>a:active,
.footer-widget>.widget-content>a:focus {
  border-bottom: 1px dashed var(--color-gray);
}

.footer-widget>.widget-content>a,
.footer-widget>.widget-content>span {
  text-decoration: none;
  color: var(--color-gray);
  font-size: 1rem;
  line-height: 2;
  font-weight: 300;
  text-align: left;
}

.footer-widget>.footer-swiss-made {
  text-decoration: none;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  overflow: hidden;
  padding: 0.25rem;
  background-color: var(--color-white);
}

.footer-widget>.footer-swiss-made>img {
  height: 3rem;
  width: auto;
  object-fit: contain;
  max-width: 100%;
  margin: auto;
}

.footer-widget>.footer-social-icon {
  margin: 0.25em;
}

.footer-widget.is-bottom {
  display: none;
}

.footer-bottom-right {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.footer-bottom-right>span {
  margin-right: 1rem;
}

.footer-widget.has-social {
  justify-content: center;
}

.footer-widget.has-social>a {
  margin: 0.5rem;
}

.footer-widget.has-store {
  justify-content: center;
}

.footer-ios-store {
  margin-right: 1rem;
}

.footer-android-store {
  margin: 0;
}

/* Section 04 */
#bens-section-04 {
  padding: 2rem 0;
}

.bens-section-04-title {
  color: var(--color-blue);
  text-align: center;
  margin: 0.25em auto;
  font-size: 2rem;
  flex: 100%;
  font-weight: 400;
}

.bens-section-04-subtitle {
  font-size: 1.5rem;
  flex: 100%;
  text-align: center;
  margin: 1rem auto;
}

.bens-section-04-subtitle.is-first {
  color: var(--color-blue);
}

.bens-section-04-subtitle.is-second {
  color: var(--color-red);
}

.bens-section-04-desc {
  margin: 0.5rem auto;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.bens-section-04-desc>li::before {
  content: "\2022";
  font-weight: bold;
  display: inline-block;
  width: 1em;
  font-size: 1.25em;
  margin-left: -1em;
}

.bens-section-04-desc.is-first>li::before {
  color: var(--color-blue);
}

.bens-section-04-desc.is-second>li::before {
  color: var(--color-red);
}

.section-map {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 1rem auto;
  flex: 100%;
}

.section-map-info {
  padding: 2rem 0;
}

.map-item {
  text-decoration: none;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 1rem;
  max-width: 320px;
  overflow: hidden;
  border-radius: 3rem;
  cursor: pointer;
  box-shadow: 0 1px 2px 0 var(--shadow-25);
  transition: all, 0.2s linear;
}

.map-item:hover,
.map-item:focus,
.map-item:active {
  box-shadow: 0 4px 16px 0 var(--shadow-50);
  transform: scale(1.1);
}

.map-caption {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  text-align: center;
  border-radius: 3rem 3rem 0 0;
}

.map-caption.is-first {
  background-color: var(--color-blue);
  color: var(--color-white);
}

.map-caption.is-second {
  background-color: var(--color-red);
  color: var(--color-white);
}

.map-caption>h4 {
  font-weight: bold;
  font-size: 1.2rem;
}

.map-caption>span {
  font-weight: 400;
  font-size: 0.9rem;
}

.map-status {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: auto;
  padding: 1rem;
  color: var(--color-black);
  background-color: var(--color-gray);
}

.is-coming-soon>.map-caption.is-second {
  background-color: var(--color-gray);
  color: var(--color-black);
}

.bens-section-04-subtitle.is-third {
  color: var(--color-white);
}

.bens-section-04-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 100%;
  width: 100%;
  color: var(--color-white);
}

.bens-section-04-item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0;
  width: 100%;
  flex: 100%;
  text-align: center;
}

.bens-section-04-item>img {
  margin-right: 0.5rem;
  height: 1.5rem;
  width: 1.5rem;
  background-color: var(--color-white);
  padding: 0.1rem 0.3rem;
  border-radius: 50%;
}

.bens-section-04-item .item-phone,
.bens-section-04-item .item-mail {
  text-decoration: none;
  color: var(--color-dark);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.bens-section-04-btn {
  text-decoration: none;
  background-color: var(--color-green);
  color: var(--color-white);
  padding: 0.5rem 3rem;
  border-radius: 1rem 2rem 1rem 1rem;
  margin-top: 1rem;
  transition: all 0.2s linear;
}

.bens-section-04-btn:hover,
.bens-section-04-btn:focus,
.bens-section-04-btn:active {
  text-decoration: none;
  color: var(--color-white);
}

.section-custom-04-btn {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: var(--color-white);
  background-color: var(--color-blue);
  padding: 0.75rem 3rem;
  margin: 1rem auto;
  border-radius: 1rem 2rem 1rem 1rem;
}

.section-custom-04-btn:hover,
.section-custom-04-btn:active,
.section-custom-04-btn:focus {
  color: var(--color-white);
}

/* 2xl */
@media (max-width: 1536px) {}

/* xl */
@media (max-width: 1280px) {}

/* lg */
@media (max-width: 1024px) {
  #bens-section-04 {
    padding: 3rem 1rem;
  }

  .section-map {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .map-item {
    flex: calc(100% / 2);
  }
}

/* md */
@media (max-width: 768px) {
  #bens-section-04 {
    padding: 3rem 1rem;
  }

  .section-map {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .map-item {
    flex: calc(100% / 2);
  }
}

/* sm */
@media (max-width: 640px) {
  #bens-section-04 {
    padding: 3rem 1rem;
  }

  .section-map {
    flex-direction: column;
    flex-wrap: nowrap;
  }

  .bens-section-04-desc {
    padding: 0;
  }

  .map-item>img {
    height: auto;
    max-width: 100%;
    object-fit: contain;
    margin: 0 auto;
  }

  .bens-section-04-item>a {
    font-size: calc(100vw / 26);
  }
}

/* 2xl */
@media (max-width: 1536px) {}

/* xl */
@media (max-width: 1280px) {
  .footer-top {
    flex-direction: column;
  }

  .footer-widget.is-bottom {
    display: flex;
  }

  .footer-bottom {
    flex-direction: column;
  }

  .footer-bottom-right {
    flex-direction: row;
  }
}

/* lg */
@media (max-width: 1024px) {
  .footer-bottom {
    padding: 2rem 0;
  }

  .footer-bottom>.bens-container {
    justify-content: center;
  }

  .footer-top {
    padding: 3rem 1rem;
  }
}

/* md */
@media (max-width: 768px) {
  .section-map-info {
    padding-top: 8rem;
  }

  .footer-bottom {
    padding: 2rem 0;
  }

  .footer-bottom>.bens-container {
    justify-content: center;
  }

  .footer-top {
    padding: 3rem 1rem;
  }
}

/* sm */
@media (max-width: 640px) {
  .footer-col {
    flex: 100%;
  }

  .footer-widget.is-bottom {
    display: flex;
    justify-content: center;
  }

  .footer-bottom {
    padding: 4rem 0;
  }

  .footer-bottom-left {
    flex: 100%;
  }

  .footer-bottom-right {
    align-items: center;
    flex: 100%;
    flex-direction: unset;
    flex-wrap: wrap;
    justify-content: center;
    padding: 1rem;
    text-align: center;
  }

  .footer-bottom-right>span {
    flex: 100%;
    margin: .5rem auto;
    width: 100%;
  }

  .footer-widget {
    margin: 1rem auto;
  }

  .footer-widget>.footer-swiss-made {
    margin-right: auto;
  }

  .footer-widget>.footer-social-icon {
    height: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .footer-widget>.footer-swiss-made>img,
  .footer-widget>.footer-social-icon>img {
    margin: auto;
    object-fit: contain;
  }

  .footer-widget>.widget-title {
    text-align: center;
  }

  .footer-widget>.widget-content>a,
  .footer-widget>.widget-content>span {
    text-align: center;
  }
}