#bens-header {
  background-color: var(--color-transparent);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  margin: 0;
  padding: 1.25rem 2em;
  height: auto;
  position: relative;
  z-index: 3;
}

#bens-header.is-fixed {
  position: fixed;
  z-index: 995;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0 1px 4px 0 var(--shadow-50);
  background: var(--color-blue);
  background: linear-gradient(
    135deg,
    var(--color-blue) 0%,
    var(--color-blue-dark) 50%
  );
}

.header-back-left {
  position: absolute;
  z-index: 1;
  right: auto;
  bottom: auto;
  border-radius: 100%;
  top: -25vw;
  left: -25vw;
  width: calc(100vw / 4 * 3);
  height: calc(100vw / 4 * 3);
  background-color: var(--color-blue);
}

.header-back-right {
  position: absolute;
  z-index: 1;
  left: auto;
  bottom: auto;
  border-radius: 100%;
  top: 50vw;
  right: -50vw;
  width: calc(100vw / 4 * 3);
  height: calc(100vw / 4 * 3);
  background-color: var(--color-blue-light);
}

/* swiss made */
.swiss-made {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  background-color: var(--color-white);
  padding: 0.25rem;
  border-radius: 0.25rem;
}

.swiss-made > img {
  max-width: 100%;
  height: 3rem;
  width: auto;
  object-fit: contain;
}

/* logo */
.bens-logo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
}

.bens-logo > img {
  height: 3.6rem;
  object-fit: contain;
}

/* menu primary */
.bens-menu-primary {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.bens-menu-primary.is-bottom {
  display: none;
  background-color: var(--color-blue-dark);
  padding: 0.5em;
}

.bens-menu-primary > a {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: normal;
  padding: 0.25em 0.5em;
  color: var(--color-white);
  background-color: var(--color-transparent);
  border-radius: 0;
  transition: all 0.2s linear;
  width: 5.5rem;
}

.bens-menu-primary > a:hover,
.bens-menu-primary > a:active,
.bens-menu-primary > a:focus,
.bens-menu-primary > a.is-active {
  font-weight: bold;
  color: var(--color-white);
  background-color: var(--color-green);
  border-radius: 1em 2em 1em 1em;
}

/* menu secondary */
.bens-menu-secondary {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 0;
}

.bens-menu-secondary-item {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: normal;
  padding: 0.5rem 0.75rem;
  color: var(--color-white);
  background-color: var(--color-blue);
  border-radius: 0.5rem;
  transition: all 0.2s linear;
  position: relative;
}

.bens-menu-secondary-item > .bens-menu {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.bens-menu-secondary-item > .bens-menu > span {
  margin-right: 0.5em;
}

.bens-menu-secondary-item > .bens-menu > img {
  width: auto;
  margin-right: 0.5rem;
}

.bens-menu-secondary-item > .bens-menu-list {
  display: none;
  position: absolute;
  top: 2.5rem;
  left: auto;
  right: 0;
  z-index: 10;
  padding: 0;
  box-shadow: 0 1px 4px 0 var(--shadow-25);
  border-radius: 0.5rem;
  width: max-content;
  overflow: hidden;
}

.bens-menu-list > a {
  text-decoration: none;
  color: var(--color-blue);
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 1rem;
  min-width: 6rem;
  transition: all 0.2s linear;
  border-bottom: 1px solid;
}

.bens-menu-list > a:last-child {
  border-bottom: none;
}

.bens-menu-list > a > img {
  width: auto;
  margin-right: 0.5rem;
}

.bens-menu-list > a:hover,
.bens-menu-list > a:active,
.bens-menu-list > a:focus,
.bens-menu-list > a.is-active {
  background-color: var(--color-blue-light);
}

.bens-menu-list > .is-separate {
  text-decoration: none;
  color: var(--color-blue);
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 1rem;
  min-width: 10rem;
  transition: all 0.2s linear;
}

.bens-menu-list > .is-separate:hover,
.bens-menu-list > .is-separate:active,
.bens-menu-list > .is-separate:focus {
  background-color: var(--color-transparent);
}

.bens-menu-secondary-item:hover,
.bens-menu-secondary-item:focus,
.bens-menu-secondary-item:active,
.bens-menu-secondary-item.is-active,
.bens-menu-download:hover,
.bens-menu-download:focus,
.bens-menu-download:active {
  color: var(--color-blue);
  background-color: var(--color-blue-light);
}

.bens-menu-secondary-item:hover > .bens-menu-list,
.bens-menu-download:hover > .bens-menu-list {
  display: flex;
  flex-direction: column;
  background-color: var(--color-white);
}

/* menu account */
.bens-menu-account {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: 1rem;
}

.bens-menu-account > a {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: var(--color-green-dark);
  color: var(--color-white);
  padding: 0.5rem 0.75rem;
  border-radius: 1rem;
  font-size: 1rem;
  font-weight: normal;
  margin-right: 1rem;
  transition: all 0.2s linear;
}

.bens-menu-account > a:hover,
.bens-menu-account > a:active,
.bens-menu-account > a:focus {
  color: var(--color-gray-dark-1);
  background-color: var(--color-blue-light);
}

.bens-menu-account > a.is-default {
  background-color: var(--color-blue);
}

.bens-menu-account > a.is-default:hover,
.bens-menu-account > a.is-default:active,
.bens-menu-account > a.is-default:focus {
  color: var(--color-blue);
  background-color: var(--color-blue-light);
}

.bens-menu-account > a:last-child {
  margin-right: 0;
}

.bens-menu-account > a > span {
  margin-right: 0.5em;
  font-weight: bold;
}

.bens-menu-account > a > img {
  width: 0.8rem;
  height: auto;
  object-fit: contain;
}

/* menu download */
.bens-menu-download {
  margin-left: 1rem;
}

.bens-menu-download:hover > .bens-menu-list {
  top: 2.5rem;
}

.bens-menu-download > .bens-menu > span {
  margin-right: 0.5em;
}

.bens-menu-download > .bens-menu > img {
  width: 0.7rem;
}

.bens-menu-list.has-store-badge > a.app-ios {
  background-color: var(--color-black);
}

.bens-menu-list.has-store-badge > a.app-android {
  background-color: var(--color-black);
}

.bens-menu-list.has-store-badge > a.app-web {
  background-color: var(--color-blue-dark);
}

.bens-menu-list.has-store-badge > a > img {
  height: 3rem;
  width: auto;
  margin: 0.25rem auto;
  object-fit: contain;
}

/* Mobile Menu Button */
#bens-menu-button {
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--color-white);
  border: 2px solid;
  border-radius: 0.5em;
  margin: auto 0 auto auto;
  padding: 0.25em;
  height: 3em;
  width: 3em;
}

#bens-menu-button.is-hidden {
  display: none;
}

/* 2xl */
@media (max-width: 1536px) {
}

/* xl */
@media (max-width: 1280px) {
  .bens-logo {
    margin-right: auto;
  }

  #bens-menu-button.is-hidden {
    display: flex;
  }

  .bens-menu-primary.is-top {
    display: none;
  }

  .bens-menu-secondary {
    display: none;
  }

  .bens-menu-account {
    display: none;
  }

  .bens-menu-download {
    display: none;
  }

  .bens-menu-primary.is-bottom {
    display: flex;
  }
}

/* lg */
@media (max-width: 1024px) {
}

/* md */
@media (max-width: 768px) {
}

/* sm */
@media (max-width: 640px) {
  #bens-header {
    padding: 1rem;
  }

  .swiss-made > img,
  .bens-logo > img {
    height: calc(100vw / 8);
    width: auto;
    object-fit: contain;
  }

  #bens-menu-button {
    height: calc(100vw / 8);
    width: calc(100vw / 8);
  }
}
