.offer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: var(--color-white);
  background-color: var(--color-red);
  padding: 0;
  margin: 0 0 1rem 0;
  height: auto;
  position: relative;
  z-index: 1;
  border-top: 0.15rem solid var(--color-green);
  border-bottom: 0.15rem solid var(--color-green);
  animation: steam 5s infinite;
}

@keyframes steam {
  50% {
    border-color: var(--color-green-light-1);
  }
}

.offer-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}

.offer-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  color: var(--color-white);
  font-size: 1.1rem;
  line-height: 1.5;
  margin: 0;
  padding: 0.5rem;
  flex: calc((100% / 12) * 8);
}

.offer-content>* {
  font-size: 1.1rem;
  line-height: 1.5;
  margin: 0.25rem;
  padding: 0;
}

.offer-media {
  flex: calc((100% / 12) * 2);
  max-width: 8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  margin: 0;
}

.offer-action {
  flex: calc((100% / 12) * 2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  margin: 0;
}

.offer-media img {
  max-width: 100%;
  width: 8rem;
  height: auto;
  object-fit: contain;
  margin: 0 auto;
}

.offer-action a {
  text-decoration: none;
  background-color: var(--color-blue);
  color: var(--color-white);
  padding: 0.5rem 1rem;
  margin: 0.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 1px 4px 0 var(--shadow-25);
  transition: all 0.2s linear;
}

.offer-action a:hover,
.offer-action a:focus,
.offer-action a:active {
  background-color: var(--color-blue-dark);
  box-shadow: 0 1px 4px 0 var(--shadow-50);
}

#offer.is-fixed {
  position: fixed;
  z-index: 995;
  top: 0;
  left: 0;
  right: 0;
}

/* 2xl */
@media (max-width: 1536px) {}

/* xl */
@media (max-width: 1280px) {}

/* lg */
@media (max-width: 1024px) {}

/* md */
@media (max-width: 768px) {
  .offer-container {
    flex-direction: column;
  }

  .offer-content {
    justify-content: center;
    text-align: center;
  }

  .offer-action {}

  .offer-media {
    display: none;
  }
}

/* sm */
@media (max-width: 640px) {}