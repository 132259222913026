.stats-boxes {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 0.5rem;
  margin: 1rem 0;
}

.stats-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  background-color: var(--color-blue-light);
  color: var(--color-blue);
  border-radius: 0 2rem 2rem 2rem;
  box-shadow: 0 0 0.25rem 0 rgba(0, 0, 0, 0.1);
  padding: 1rem 0.5rem;
  width: 12rem;
  gap: 0.25rem;
}

.stats-box-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 0.25rem;
}

.stats-box-content > * {
  margin: 0;
}

.stats-box-content > h3 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  line-height: 1.5;
}

.stats-box-content > p {
  font-size: 0.8rem;
}

.stats-field {
  margin-right: 0.25rem;
  margin-left: 0.25rem;
  background-color: var(--color-red);
  color: var(--color-white);
  letter-spacing: 0.5rem;
  text-indent: 0.5rem;
  padding: 0.1rem 0.2rem;
  border-radius: 0.25rem;
}

.stats-field.finished {
  background-color: var(--color-transparent);
  letter-spacing: unset;
  text-indent: unset;
  padding: 0;
}

.stats-value {
  display: inline-block;
  background-color: var(--color-red);
  color: var(--color-white);
  padding: 0.1rem 0.2rem;
  border-radius: 0.25rem;
  margin: 0.1rem;
  width: 1.25rem;
}

.stats-box-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}
.stats-box-icon > img {
  width: 4rem;
  height: 4rem;
  object-fit: contain;
}

.stats-bottom {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin: 1rem 0;
}

.stats-bottom p {
  color: var(--color-body);
  font-size: 1rem;
  line-height: 1.5;
  margin: 0;
}

.stats-bottom img {
  height: 3rem;
  margin: 0;
  max-width: 100%;
  object-fit: contain;
  object-position: center;
  width: auto;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.25rem 0 rgba(0, 0, 0, 0.1);
}
