#app {
  position: relative;
  overflow-x: hidden;
}

#audio {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: auto;
  margin: auto auto 1rem 1rem;
  z-index: 1000;
}

/* sm */
@media (max-width: 640px) {
  #audio {
    margin-left: auto;
  }
}